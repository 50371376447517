@import '../../../../theme/theme';

.activeSearchFieldContainer,
.projectListControls {
  background-color: $light;
  height: 47px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  max-width: 355px;
  justify-content: space-between;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.12);
  margin-left: 2px;
  @include xsPhoneView {
    height: 36px;
    min-width: 200px;
    border-radius: 8px;
    border: 1px solid rgba($borderColor, 0.5);

    &.onlyMapMode {
      max-width: none;
      width: 100%;
    }
  }
}

.activeSearchFieldContainer {
  @include xsPhoneView {
    input {
      font-size: $fontXSmall;
    }
  }
}
.projectListHeaderText {
  font-weight: 600;
  margin-left: 20px;
}
.tabsContainer,
.projectListControlsMobile {
  display: flex;
  justify-content: space-between;
  gap: 4px;
  padding: 0px 10px;
  &.mapModeControls {
    justify-content: flex-end;
  }
}

.projectListControlsMobile {
  gap: 10px;
}

.impersonationMode {
  margin-top: 20px;
}

.activeSearchIcon {
  margin-left: 24px;
  margin-top: 4px;
  > svg {
    width: 16px;
    @include xsPhoneView {
      width: 12px;
    }
  }
  @include xsPhoneView {
    margin-left: 11px;
  }
}

.textField {
  margin-left: 17px;
}

.crossIcon {
  margin-left: 9px;
  margin-top: 5px;
  cursor: pointer;
  padding-right: 15px;
  @include xsPhoneView {
    padding-right: 10px;
  }
  > svg {
    width: 16px;
    @include xsPhoneView {
      width: 12px;
    }
  }
}

.projectLabel {
  display: flex;
  margin-left: 7px;
}

// mobile tab styles

.tabContainerSecondary,
.tabContainer {
  border-radius: 5px;
  border: 2px solid $primaryColorNew;
  display: flex;
  overflow: hidden;

  &.mapModeTabs {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.12);
  }
}

.tabContainer {
  height: 28px;
}

.tabContainerSecondary {
  min-width: 70px;
  border-radius: 6px;
}

.selectedTabButton,
.unselectedTabButton {
  width: 100%;
  padding: 7px;
  line-height: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  > .label {
    font-weight: 700;
    font-size: $fontXXSmall;
    display: flex;
  }
  .noOfProject {
    font-weight: 400;
    font-size: $fontXXSmall;
    margin-left: 1px;
  }
}
.selectedTabButton {
  background: $primaryColorNew;
  color: $light;
}

.unselectedTabButton {
  background: $light;
  color: rgba($dark, 1);
}

.projectCount {
  font-weight: 400;
  margin-left: 3px;
}

.iconsContainerMobile {
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.4s ease-in-out;
  button {
    width: 28px;
    height: 28px;
    border-radius: 7.78px;
    background: $backgroundColor;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.mapModeButtons {
    .buttonContainer,
    > button {
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.12);
    }
  }
}

.iconsContainer {
  width: max-content;
  display: flex;
  gap: 20px;
  padding-right: 19px;
  margin-top: 4px;
  button {
    cursor: pointer;
  }
}

.label {
  font-weight: 700;
  font-size: $fontXXSmall;
}

// filter styles
.buttonContainer {
  position: relative;
  .activeIndicator {
    position: absolute;
    left: 10px;
    top: -4px;
    z-index: 1;
    background-color: $primaryColorNew;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    @include xsPhoneView {
      width: 8px;
      height: 8px;
      top: 4px;
      left: 15px;
    }
  }
}

.filterDropDownContainer {
  display: flex;
  max-width: 338px;
  justify-content: flex-end;
}

.classificationListContainer {
  min-width: 182px;
  background: $backgroundColor;
  padding: 16px;
  margin-top: 10px;
  border-radius: 12px;
  position: absolute;
  z-index: 2;
  box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.2);
  left: 193px;

  .listMode {
    left: 168px;
    top: 67px;
  }

  @include xsPhoneView {
    left: 127px;
    top: 29px;
  }
}

.classificationItem {
  display: flex;
  color: rgba($dark, 1);
  width: 100%;
  align-items: center;
  gap: 4px;
}

.unselected {
  font-size: $fontXXSmall;
  line-height: normal;
}

.selected {
  font-weight: 700;
  font-size: $fontXXSmall;
}

.filterButton {
  display: flex;
  width: 100%;
  flex-direction: column;
  cursor: pointer;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  align-items: flex-start;
  color: rgba(51, 51, 51, 1);
}

.hrLine {
  width: 100%;
}

.filterResultContainer {
  margin-left: 19px;
  font-weight: 600;
}

.filterResultContainerMobile {
  width: max-content;
  height: 26px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.donationFilterButton {
  width: 100%;
  color: rgba($dark, 1);

  .donationFilterLabel {
    display: flex;
    align-items: center;
    gap: 3px;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
