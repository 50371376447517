@import '../../../../theme/theme';

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.singleProject {
  border: 2px solid $light;
  border-radius: 16px;
  position: relative;
  box-shadow: 4px 8px 16px 0px rgba(0, 0, 0, 0.1);
  max-width: 356px;
  background-color: #fff;
  @include xsPhoneView {
    max-width: 100%;
    box-shadow: none;
    margin-left: 0px;
  }
}
.projectDetailsSnippetMobile {
  padding: 0px 9px;
}
//project image
.projectImage {
  height: 160px;
  width: 100%;
  position: relative;
  transition: all 1s ease;
  border-radius: 14px 14px 0px 0px;
  overflow: hidden;
  > .projectImageBlock {
    transition: all 0.3s ease;
    position: absolute;
    bottom: 12px;
    left: 13px;
  }
}

.projectImageSecondary {
  @include xsPhoneView {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    height: 180px;
  }
}

.gradientOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
}

.projectImageFile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;

  &.hidden {
    opacity: 0;
  }
}

// project info
.projectType,
.projectEcosystem {
  font-family: $primaryFontFamily;
  font-size: $fontXXSmall;
  color: $light;
  font-weight: bold;
  text-transform: uppercase;
}

.projectType {
  line-height: 0.9em;
}

.projectName {
  font-family: $primaryFontFamily;
  font-size: $fontMedium;
  color: $light;
  padding-right: 16px;
  display: flex;
  line-height: 26px;
}

.verifiedIcon {
  display: inline-block;
  vertical-align: top;
  width: 0.95em;
  min-width: 0.95em;
  line-height: 0.95em;
  margin-left: 4px;
  margin-top: 2px;
}

.topProjectBadge {
  position: absolute;
  top: 12px;
  right: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  background: $topProjectBackgroundColor;
  border-radius: 14px;
  padding: 2px 8px 2px 2px;
  font-weight: 700;
  font-size: 12px;

  * {
    color: #6d4230;
  }

  .badgeIcon {
    background-color: $light;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 14px;
      width: 10px;
    }
  }
}

/* Progress Bar */
.progressBar {
  height: 4px;
  width: 100%;
  background-color: $charcoalGray;
}

.progressBarHighlight {
  height: 4px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.projectInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
}

.projectInfoSubContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.targetLocation {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.target {
  font-family: $primaryFontFamily;
  font-size: 13px;
  color: $primaryFontColor;
  font-weight: bold;
  margin-right: 4px;
}

.projectTpoName {
  cursor: pointer;
  position: relative;
  text-align: center;
  color: $grayFontColorNew;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.5em;
  // margin-top: 10px;
  padding: 9px 0;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  background: $primaryColorNewTransparent;
  &.noDonation {
    background: $mediumGrayColorTransparent;
  }
  &.tpoBackground {
    background: $lightOrange;
  }
}

.projectTpoNameSecondary {
  @include xsPhoneView {
    border-radius: 0px;
    margin-top: 0px;
  }
}

.projectCost {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.perUnitCost {
  font-family: $secondaryFontFamily;
  font-size: $fontXSmall;
  color: $primaryFontColor;
  margin-top: 6px;
  font-weight: 700;
}

.perUnitCost {
  font-family: $primaryFontFamily;
  font-weight: 600;
}

.topProjectReportsContainer {
  margin: 10px;
}

.projectTypeIcon {
  display: inline-block;
  width: 0.95em;
  min-width: 0.95em;
  margin-right: 4px;
  font-size: $fontXXSmall;
  line-height: 0.3em;
}

.topProject {
  background-color: $topProjectBackgroundColor;
  border: none;
  &:hover {
    background-color: $topProjectTransparentColor;
    border: none;
  }
}

.regularProject {
  background-color: $primaryColorNew;
}

.nonDonatableProject {
  background-color: $nonDonatableProjectBackgroundColor;
}
.projectHoverIcon {
  display: inline-flex;
  font-size: 10px;
  font-weight: 700;
  color: $nonDonatableProjectBackgroundColor;
  align-items: center;
  gap: 4px;
  line-height: normal;
}

.projectInfoPopupContainer {
  margin: 10px;
  max-width: 250px;
}

.projectEcosystemOrTypeContainer {
  display: flex;
  align-items: center;
}

.country {
  font-weight: 400;
}

.backButton {
  position: absolute;
  z-index: 2;
  left: 0px;
  cursor: pointer;
}

.reports_container {
  display: flex;
  gap: 10px;
  width: 100%;
  .reports_description {
    display: flex;
    flex-direction: column;
    gap: 9px;
    a {
      font-weight: bold;
    }
  }

  @include mdTabletView {
    width: 328px;
  }
}
