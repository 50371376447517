@import '../../../../theme/theme';

$backgroundColor: #fff;
$light: #fff;
$dangerColorNew: #e86f56;
$dangerBackgroundColor: rgba(249, 220, 214);
// $fontXSmall: '12px';

.arrowTop {
  border: 10px solid transparent;
  border-top-color: $light;
  position: absolute;
  bottom: -20px;
}
.arrowBottom {
  border: 10px solid transparent;
  border-bottom-color: $dangerBackgroundColor;
  position: absolute;
  top: -20px;
}

//fireIcon style : to be removed
.fireIcon {
  width: 100%;
  display: flex;
  justify-content: center;
  // margin-top: 20%;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
}

.popupContainer {
  background-color: $backgroundColor;
  min-width: 200px;
  border-radius: 8px;
  cursor: default;
  svg {
    overflow: visible;
  }
}

.popupTitle {
  display: flex;
  justify-content: space-between;
  background: rgba(232, 111, 86, 0.2); //to be replaced
  align-items: center;
  padding: 10px 14px;
  border-radius: 8px 8px 0 0;

  .titleText {
    display: flex;
    align-items: center;
    gap: 7px;
    font-weight: 700;
    color: #333; //to be replaced
    font-size: $fontXSmall;
  }
}

.popupText {
  padding: 14px;
  font-size: $fontXSmall;
  font-weight: 400;
  color: #4f4f4f; //to be replaced
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  .coordinates {
    color: #828282; //to be replaced
  }
  span {
    font-weight: 700;
  }
}

.setUpAlertsContainer {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  text-wrap: nowrap;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  .setUpAlerts {
    span {
      color: $dangerColorNew;
    }
  }
}

.infoIconPopupContainer {
  padding: 10px;
  max-width: 123px;
  font-size: $fontXXSmall;
  border-radius: 8px;
}

.timeDuration {
  font-size: $fontXXSmall;
  font-weight: 600;
  color: $dangerColorNew;
  display: flex;
  gap: 3px;
}

//to be removed
@include xsPhoneView {
  .fireIcon {
    margin-top: 60%;
  }
}
