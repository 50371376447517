@import '../../../../theme/theme';

.mapFeatureExplorer {
  @include smTabletView {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 12px;
    pointer-events: auto;
    position: relative;
  }
}

.exploreButton {
  @include smTabletView {
    position: relative;
    z-index: 10;
    pointer-events: auto;
  }
}

.exploreButtonContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h3 {
    font-size: $fontSmall;
    @include xsPhoneView {
      font-size: $fontXXSmall;
    }
  }

  p {
    font-weight: 400;
  }
}

.exploreMainContainer {
  position: relative;
  z-index: 50;
  pointer-events: auto;
  max-width: 240px;
  background-color: #fff;
  border-radius: 12px;
  padding: 12px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.12);

  &::-webkit-scrollbar {
    display: none;
  }

  @include smTabletView {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  @include xsPhoneView {
    position: absolute;
    max-height: 100vh;
    height: 100vh;
    height: -webkit-fill-available;
    height: stretch;
    overflow-y: scroll;
    min-width: 293px;
    border-radius: 12px 0 0 12px;
    right: 0px;
    margin-top: 0;
  }
}

.exploreItemsContainer {
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primaryColorNew;
    border-radius: 10px;
    border: 2px solid #fff;
  }

  @include smTabletView {
    flex: 1 1 auto;
  }

  @include xsPhoneView {
    max-height: none;
  }
}

.exploreItemSection {
  background: rgba(0, 122, 73, 0.05);
  border-radius: 8px;
  padding: 8px 9px;
  font-size: 12px;
  margin-bottom: 10px;

  h2 {
    font-weight: 600;
    margin-bottom: 6px;
    font-size: $fontSmall;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.singleLayerOption {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  padding: 6px 0;
  border-bottom: 1px solid $horizontalLineColor;

  &:last-child {
    border-bottom: none;
  }

  .layerLabel {
    cursor: default;

    &.additionalInfo {
      cursor: help;
      p {
        text-decoration: $horizontalLineColor dashed underline;
        text-underline-offset: 2px;
      }
    }
  }
}

.layerInfoPopupContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 189px;
  background: #fff;
  padding: 12px;
  font-size: 10px;

  .label {
    font-weight: 600;
    color: rgba(130, 130, 130, 1);
    text-transform: uppercase;
  }
  .source {
    color: $primaryDarkColor;
  }
}

.switchContainer {
  height: 21px; // Updated from 14px
  width: 36px; // Updated from 24px
}

.exploreDescription {
  height: fit-content;
  font-size: var(--font-xx-extra-small);
  border-radius: 6px;
}
