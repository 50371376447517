.markerContainer {
  position: relative;
}

.marker {
  width: 30px;
  cursor: pointer;

  > svg {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
}
