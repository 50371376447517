@import '../../../../theme/theme';

.projectBadge {
  display: flex;
  height: 27px;
  gap: 3.87px;
  border-radius: 0px 16px;
  border: 2px solid $backgroundColor;
  align-items: center;
  padding: 0px 17px;
  white-space: nowrap;
  font-weight: 700;
  background: $dark;
  width: fit-content;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  right: -2px;
  top: -2px;
}
.badgeIcon {
  display: flex;
  align-items: center;
  > svg path {
    fill: $light;
  }
}
.badgeTitle {
  font-size: $fontXXSmall;
  color: $backgroundColor;
}
.tooltipContent {
  padding: 12px 16px;
  max-width: 300px;
}

/* .standardsLink {
  font-weight: 400;
  color: $primaryColor;
} */
