@import '../../../../theme/theme';

.lngSwitcher {
  position: fixed;
  bottom: 0px;
  right: 20px;
  font-size: 11px;
  color: $primaryFontColor;
  cursor: pointer;
  background-color: #f2f2f2;
  padding: 6px 18px 4px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  font-family: $primaryFontFamily;
  z-index: 11;
  display: flex;
  flex-direction: row;
  font-weight: 400;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow: visible;

  .languageModalButton,
  > a,
  > span > a,
  .mapCreditsTrigger {
    text-decoration: none;
    color: $primaryFontColor;
    transition: opacity 0.15s ease;

    &:hover,
    &:active {
      opacity: 0.7;
    }
  }

  .mapCreditsContainer {
    margin-left: 10px;
    cursor: pointer;
  }

  > a {
    margin-left: 10px;
  }

  > span {
    margin-left: 10px;
    a {
      text-decoration: none;
      color: $primaryFontColor;
    }
  }

  @include xsPhoneView {
    > a,
    .mapCreditsContainer {
      margin-left: 0px;
    }
    padding: 5px 7px 4px;
    width: 100%;
    right: 0;
    bottom: 0;
    border-radius: 0;
    display: flex;
    justify-content: center;
    column-gap: 4px;
  }
}

.popover {
  position: relative;

  .popoverContent {
    position: absolute;
    z-index: 10;
    background-color: $backgroundColor;
    border-radius: 10px;
    padding: 12px;
    text-align: center;
    display: none;
    flex-direction: column;
    font-weight: 400;
  }

  &:hover,
  &:active {
    cursor: pointer;
    .popoverContent {
      display: flex;
    }
  }
}
