.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  max-width: 293px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 12px 0 0 12px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 12px 12px 16px;
  border-radius: 12px 0 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 1;
}

.exploreLabel {
  display: flex;
  gap: 8px;
  align-items: center;

  p {
    font-size: 14px;
    font-weight: 700;
  }

  svg {
    width: 16px;
  }
}

.scrollableContent {
  flex: 1;
  overflow-y: auto;
  padding: 12px;
  background-color: #fff;
  border-radius: 0;
  transition: border-radius 0.2s ease;

  &::-webkit-scrollbar {
    display: none;
  }

  &.atBottom {
    border-radius: 0 0 0 12px;
  }
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  cursor: pointer;
  background: transparent;
  border: none;

  svg {
    width: 16px;
    path {
      fill: currentColor;
    }
  }
}
