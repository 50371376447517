@import '../../../../theme/theme';

.carouselContent {
  width: 100%;
  height: 100%;
  background-position: center center !important;
  background-size: cover !important;
}
.carouselContentExpand {
  border-radius: 0px;
  height: 40%;
}
.carouselContentText {
  position: absolute;
  bottom: 38px;
  left: 41px;
  color: #fff;
  font-size: $fontXSmall;
  text-transform: capitalize;
  max-width: 90%;
}

.imageSliderContainer {
  position: relative;
  border-radius: 13px;
  overflow: hidden;
  > button {
    position: absolute;
    z-index: 9999;
    width: 18px;
    top: 13px;
    right: 13px;
    cursor: pointer;
    pointer-events: auto;
  }
}

.expandedImageSliderContainer {
  position: relative;
  width: 800px;
  border-radius: 16px;
  overflow: hidden;
  > button {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 1000;
    background: #fff;
    cursor: pointer;
  }
}
